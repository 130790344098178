:root[theme-mode=light] {
  --bg: #9FE4C5;
  --title-color: #002143;
  --content-color: #000;
  --color1: #BDFFAD;
}
body {
  margin: 0;
  font-family: DXRigraf, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: DXRigraf, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
